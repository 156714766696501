<template>
  <base-dialog
    :visible.sync="currentVisible"
    width="1000px"
    :showFooter="false"
    title="查看"
    class="dialog"
  >
    <form-info
      :componentList="basicInfo"
      :model="backData"
      :showbor="true"
      :count='count'
    />
    <template slot="footer">
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false;"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { basicInfo } from '../utils/look-details-config'
export default {
  props: {
    visible: Boolean,
    backData: Object
  },
  components: {
    BaseDialog,
    FormInfo,
    BaseButton
  },
  data () {
    return {
      count: 0
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    basicInfo () {
      return basicInfo(this)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog{
  height: 450px;
}
</style>
