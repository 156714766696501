<!-- 正式授信管理列表 -->
<template>
  <div class="financingDecisionManageList">
    <!-- 条件查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
      <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"></base-button>
    </div>
    </base-form>
    <!-- 列表数据 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :dataSource.sync="tableData"
      :api="api"
      :getApi="'getCreditResult'"
      :loadCount="loadCount"
      :queryParas='queryParas'
      @expand-change="expandChange"
      ref="tableData"
      :webPage="false">
      <template slot="index" slot-scope="scope">{{scope.$index + 1}}</template>
      <template slot="action" slot-scope="scope">
        <IconButton v-if="scope.row.approvalResults == 1" @click="applyFor(scope.row, 'details')" content="推送产值" icon="iconfont icontuisong"></IconButton>
        <!-- <IconButton @click="lookDetails(scope.row)" content="查看" icon="iconfont iconchakan"></IconButton> -->
      </template>
    </base-table>
    <!-- 清分弹窗 -->
    <clearing-dialog :visible.sync="visible"></clearing-dialog>
    <!-- 查看详情 -->
    <look-bank-info :visible.sync="lookVisible" :backData="backData"></look-bank-info>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseForm from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable, expandTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import ClearingDialog from './components/clearing-dialog.vue'
import LookBankInfo from './components/look-bank-info.vue'
import { psbcApi } from '@/api/psbcApi'
import { getDictLists } from '@/filters/fromDict'
import { fundManagementApi } from '@/api/fundManagementApi'
import storage from '@/utils/storage'
export default {
  name: 'financingDecisionManageList',
  components: { baseForm, BaseTable, BaseButton, IconButton, ClearingDialog, LookBankInfo },
  data () {
    return {
      visible: false,
      count: 0,
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      lookVisible: false,
      backData: {},
      fundsproviderData: []
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    expandColumns () {
      return expandTable(this)
    },
    api () {
      return psbcApi
    },
    fileMakeData () {
      return getDictLists('FILE_MAKE_STATUS')
    }
  },
  mounted () {
    this.getFundsprovider()
    this.handleFilter()
  },
  activated () {
    this.getFundsprovider()
    this.handleFilter()
  },
  methods: {
    // 获取资金方下拉数据
    getFundsprovider () {
      const params = {
        replyStatus: 1
      }
      fundManagementApi.getFundsproviderInfo(params).then(res => {
        this.fundsproviderData = res.data
      })
    },
    handleFilter () {
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 查看按钮显示判断
    detailShow (data) {
      return (data.reviewStatus === 10 || data.reviewStatus === 20 || data.reviewStatus === 30 || data.reviewStatus === 90)
    },
    // 展开行
    expandChange (row) {
      console.log(row)
      row.tableData = [{}, {}]
      row.expand = true
    },
    // 推送产值
    applyFor (data, type) {
      // this.visible = true
      this.$router.push({
        path: '/business/pushProduction',
        query: { type: type }
      })
      storage.setSession('creditData', data)
    },
    // 查看授信详情
    lookDetails (data) {
      this.backData = data
      console.log(this.backData)
      this.lookVisible = true
    }
  }
}
</script>
